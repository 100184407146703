import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { sortBy } from "lodash-es";
import type { LocationQuery } from "vue-router";
import { format } from "date-fns";
import {
  BootstrapData,
  BootstrapSettings,
  NavMenuItem,
  IntegrationsType,
  StorePayloadIntegrationCallbackData,
  StorePayloadIntegrationObjectNameData,
} from "~/types/api/bootstrap.types";
import { Cart } from "@/types/api/cart.types";
import {
  BlockContent,
  IBlockTranslation,
  RichTextModuleData,
} from "~/types/api/block.types";
import { FiltersData, FilterType } from "~/types/api/filters.types";
import { WishlistData } from "~/types/api/wishlist.types";
import { SignInData } from "@/types/api/auth.types";
import {
  PaymentGateway,
  PaymentMethodData,
  LanguageI,
  IPopup,
  IDiscountCampaign,
  IDiscountCampaignPopup,
  IDiscountCampaignsData,
} from "@/types/api/general.types";
import { OrderData } from "@/types/api/orders.types";

export interface BreadcrumbsItem {
  text: string;
  disabled: boolean;
  href?: string;
  action?: any;
  active?: boolean;
  id?: number;
}

export type BreadcrumbsArray = Array<BreadcrumbsItem>;
interface State {
  bootstrap: BootstrapData | undefined | null;
  // topPromotion: string | undefined;
  footer: {
    copyright: string | undefined;
    leftColumn: BlockContent | undefined;
    rightColumn: string | undefined;
    centerColumn: string | undefined;
    certificatesEntities: RichTextModuleData | null | undefined;
    bottomInfo: RichTextModuleData | null | undefined;
  };
  filtersData: FiltersData | undefined;

  wishlist: WishlistData | undefined;
  cart: Cart | undefined | null;
  isIndivLoaded: boolean;
  userInfo: SignInData | null;
  isSignInDrawerOpen: boolean;
  isSignUpDrawerOpen: boolean;
  isStripeCDNLoaded: boolean;
  isPaypalCDNLoaded: boolean;
  gatewaysData: PaymentGateway[] | null;
  paymentMethodsData: PaymentMethodData[];
  globalPaymentLoading: boolean;
  guestOrderInfo: OrderData | null;
  languages: LanguageI[] | null;
  integrationCallbackStatuses: {
    [key: string]: boolean;
  };
  recentlyViewedStorage: any;
  breadcrumbs: BreadcrumbsArray;
  sitePopup: IPopup | IDiscountCampaignPopup | null;
  discountCampaignsData: IDiscountCampaignsData;
  systemData: {
    loginFooter: RichTextModuleData | null;
  };
  systemOverlay: boolean;
  cacheVersions: null | Record<string, number>;
  isWishlistDrawerOpen: boolean;
}

export const useGeneralStore = defineStore("general", {
  state: (): State => ({
    bootstrap: null,
    // topPromotion: "",
    footer: {
      copyright: "",
      leftColumn: undefined,
      rightColumn: "",
      centerColumn: "",
      certificatesEntities: null,
      bottomInfo: null,
    },
    filtersData: undefined,
    wishlist: undefined,
    cart: undefined,
    isIndivLoaded: false,
    userInfo: null,
    isSignInDrawerOpen: false,
    isSignUpDrawerOpen: false,
    isStripeCDNLoaded: false,
    isPaypalCDNLoaded: false,
    gatewaysData: null,
    globalPaymentLoading: false,
    paymentMethodsData: [],
    guestOrderInfo: null,
    languages: [],
    integrationCallbackStatuses: {
      TYPE_GOOGLE_ANALYTICS: false,
      TYPE_FACEBOOK_PIXEL: false,
    },
    recentlyViewedStorage: useStorage<CardProduct[]>("recentlyViewed", []),
    breadcrumbs: [],
    sitePopup: null,
    discountCampaignsData: {
      topBanner: null,
      popups: [],
    },
    systemData: {
      loginFooter: null,
    },
    systemOverlay: false,
    cacheVersions: null,
    isWishlistDrawerOpen: false,
  }),

  getters: {
    headerMenu(state): NavMenuItem[] {
      return state.bootstrap?.menu?.header?.items || [];
    },
    footerMenu(state): NavMenuItem[] {
      return state.bootstrap?.menu?.footer?.items || [];
    },
    footerMenuMobile(state): NavMenuItem[] {
      return state.bootstrap?.menu?.footer_mobile?.items || [];
    },
    socialsMenu(state): NavMenuItem[] {
      return state.bootstrap?.menu?.socials?.items || [];
    },
    settings(state): BootstrapSettings | undefined {
      return state.bootstrap?.settings;
    },
    allFilters(state) {
      return (collectionId?: number[], categoryIds?: number[]) => {
        return state.filtersData
          ? Object.entries(state.filtersData).reduce(
              (acc, [key, value]) => {
                acc[key] = value
                  ? value
                      .reduce((accValue, el) => {
                        if (
                          (!isEmpty(categoryIds) && el?.categories) ||
                          (!isEmpty(collectionId) && el?.collections)
                        ) {
                          if (!isEmpty(categoryIds) && el?.categories) {
                            categoryIds?.forEach((id) => {
                              const alreadyExist = accValue.find(
                                // fix duplicate filters
                                (i) => i.id === el.id,
                              );
                              if (
                                el?.categories?.includes(id) &&
                                !alreadyExist
                              ) {
                                accValue.push({
                                  ...el,
                                  type: key,
                                  [key === "collections" ? "url" : "value"]:
                                    key === "collections" ? el.slug : el.sku,
                                });
                              }
                            });
                          }
                          if (!isEmpty(collectionId) && el?.collections) {
                            collectionId?.forEach((id) => {
                              const alreadyExist = accValue.find(
                                // fix duplicate filters
                                (i) => i.id === el.id,
                              );
                              if (
                                el?.collections?.includes(id) &&
                                !alreadyExist
                              ) {
                                accValue.push({
                                  ...el,
                                  type: key,
                                  [key === "collections" ? "url" : "value"]:
                                    key === "collections" ? el.slug : el.sku,
                                });
                              }
                            });
                          }
                        } else {
                          accValue.push({
                            ...el,
                            type: key,
                            [key === "collections" ? "url" : "value"]:
                              key === "collections" ? el.slug : el.sku,
                          });
                        }
                        return accValue;
                      }, [] as FilterType[])
                      .sort((a, b) => a.sort_order - b.sort_order)
                  : [];
                return acc;
              },
              {} as { [key: string]: FilterType[] },
            )
          : null;
      };
    },
    getIsIndivLoaded(state): boolean {
      return state.isIndivLoaded;
    },
    isAuthenticated(state) {
      return !!state.userInfo;
    },
    getToken(state) {
      if (state.userInfo) {
        return state.userInfo?.access_token?.token;
      }
      return null;
    },
    getCurrentLanguage(state) {
      return (code: string) => {
        return state.languages?.find((lang) => lang.slug === code);
      };
    },
    getDefaultLanguage(state) {
      return state.languages?.find((lang) => lang.is_default);
    },
    isIntegrationEnabled(state) {
      return function (integrationType: IntegrationsType) {
        return state.bootstrap?.enabled_integrations.includes(integrationType);
      };
    },
    getIntegrationCallbackStatuses(state) {
      return state.integrationCallbackStatuses;
    },
    getFirebaseIntegration(state) {
      return state.bootstrap?.integrations?.[IntegrationsType.TYPE_FIREBASE];
    },
    seoFilterName(state) {
      return function (routeQuery: LocationQuery) {
        const materialQuery = Array.isArray(routeQuery.materials)
          ? routeQuery.materials[0]
          : routeQuery.materials;
        if (materialQuery && state.filtersData?.materials?.length) {
          return (
            state.filtersData.materials.find(
              (item: any) => item.sku === materialQuery,
            )?.currentTranslation?.title || ""
          );
        }
        const categoryQuery = Array.isArray(routeQuery.categories)
          ? routeQuery.categories[0]
          : routeQuery.categories;
        if (categoryQuery && state.filtersData?.categories?.length) {
          return (
            state.filtersData.categories.find(
              (item: any) => item.sku === categoryQuery,
            )?.currentTranslation?.title || ""
          );
        }
        const collectionQuery = Array.isArray(routeQuery.collections)
          ? routeQuery.collections[0]
          : routeQuery.collections;
        if (collectionQuery && state.filtersData?.collections?.length) {
          return (
            state.filtersData.collections.find(
              (item: any) => item.id === collectionQuery,
            )?.currentTranslation?.title || ""
          );
        }
        return "";
      };
    },
    getSystemDateFormat(state) {
      function isCorrectFormat(settingsDateFormat: string) {
        try {
          format(new Date(), settingsDateFormat);
        } catch (err) {
          console.log("System settings Date format error", err);
          return false;
        }
        return true;
      }
      return state.bootstrap?.settings?.date_format &&
        isCorrectFormat(state.bootstrap?.settings.date_format)
        ? state.bootstrap?.settings.date_format
        : "dd.MM.yyy";
    },
  },

  actions: {
    setIntegrationCallbackStatus(payload: StorePayloadIntegrationCallbackData) {
      this.integrationCallbackStatuses[payload.integrationName] =
        payload.status;
    },
    setIntegrationStatusByObjectName(
      payload: StorePayloadIntegrationObjectNameData,
    ) {
      if (typeof window === "undefined") {
        return;
      }
      let intervalCount = 0;
      const interval = setInterval(() => {
        intervalCount++;
        if (intervalCount > 30) {
          clearInterval(interval);
        }
        if (window[payload.integrationObjectName as keyof typeof window]) {
          clearInterval(interval);
          this.setIntegrationCallbackStatus({
            integrationName: payload.integrationName,
            status: true,
          });
        }
      }, 500);
    },
    setGuestOrderInfo(payload: OrderData) {
      this.guestOrderInfo = payload;
    },
    setGlobalPaymentLoading(payload: boolean) {
      this.globalPaymentLoading = payload;
    },
    setStripeCDNLoaded(payload: boolean) {
      this.isStripeCDNLoaded = payload;
    },
    setPaypalCDNLoaded(payload: boolean) {
      this.isPaypalCDNLoaded = payload;
    },
    setIndevLoaded() {
      this.isIndivLoaded = true;
    },
    async getCart() {
      await nextTick();
      const { cartUniqueId, userInfoData } = useCookiesService();

      const { $api } = useNuxtApp();
      const { data: dataCart } = await $api.cart.getCart(
        userInfoData.value,
        cartUniqueId.value,
      );
      this.cart = dataCart;
      if (this.cart) {
        cartUniqueId.value = this.cart.unique_id;
      }
    },
    async getWishList() {
      const { wishlistId } = useCookiesService();
      const { $api } = useNuxtApp();
      const { data: dataWishlist } = await $api.wishlist.getWishList(
        wishlistId.value,
      );
      this.wishlist = dataWishlist;
      if (this.wishlist) {
        wishlistId.value = this.wishlist.unique_id;
      }
    },
    setCart(payload: Cart | null) {
      const { cartUniqueId } = useCookiesService();

      this.cart = payload;
      if (this.cart) {
        cartUniqueId.value = this.cart.unique_id;
      } else {
        cartUniqueId.value = "";
      }
    },

    setUserInfo(userData: SignInData | null, replace?: boolean) {
      const { userInfoData } = useCookiesService();
      // this.userInfo = userData;
      // userInfoData.value = userData ? JSON.stringify(userData) : null;

      if (!userData) {
        this.userInfo = null;
        userInfoData.value = null;
        return;
      }
      this.userInfo = JSON.parse(JSON.stringify(userData)); // maybe this
      if (!userInfoData.value || replace) {
        if (userData.role) {
          delete userData.role;
        }
        userInfoData.value = JSON.parse(JSON.stringify(userData)); // or this
      }
    },
    getLanguages() {
      // const langRes = await generalApi.getLanguages();
      // console.log("langRes - ", langRes.data.value?.data);

      // this.languages = langRes.data.value?.data || null;
      const hardcodeRes = [
        { id: 1, slug: "en", name: "English", is_default: true },
      ];
      this.languages = hardcodeRes;
    },

    async clientAppInit({
      cartUniqueId,
      getCartByCartID,
    }: {
      cartUniqueId: MaybeRef<string | null | undefined>;
      getCartByCartID: boolean;
    }) {
      const { wishlistId, userInfoData } = useCookiesService();

      const { $api } = useNuxtApp();
      const { data: apiData } = await useAsyncData(
        "clientAppInit",
        async () => {
          const [responseCart, responseWishlist] = await Promise.all([
            $api.cart.getCart(
              userInfoData.value,
              unref(cartUniqueId),
              getCartByCartID,
            ),
            userInfoData.value
              ? $api.wishlist.getWishList(wishlistId.value)
              : null,
          ]);

          return {
            dataCart: responseCart.data,
            dataWishlist: responseWishlist?.data,
          };
        },
      );

      const { dataCart, dataWishlist } = apiData.value || {};

      this.cart = dataCart;
      if (this.cart && isRef(cartUniqueId)) {
        cartUniqueId.value = this.cart.unique_id;
      }
      this.wishlist = dataWishlist;
      if (this.wishlist) {
        wishlistId.value = this.wishlist.unique_id;
      }
    },
    async appInit() {
      const { userInfoData } = useCookiesService();

      if (userInfoData.value) {
        this.setUserInfo(userInfoData.value, false);
      }
      const { $api } = useNuxtApp();
      const { data: apiData } = await useAsyncData("appInit", async () => {
        const [
          responseCopyright,
          responseBootstrap,
          responseGateways,
          responsePaymentMethods,
          responseFooterLeftColumn,
          // responseFooterCenterColumn,
          // responseFooterRightColumn,
          // responseCertificatesEntity,
          // responseBottomInfo,
          responseDiscountCampaigns,
          responseSystemLoginFooter,
        ] = await Promise.all([
          $api.blocks.getBlock("footer-copyright"),
          $api.general.getBootstrap(),
          $api.general.getPaymentGateways(),
          $api.general.getPaymentMethodsList(),
          $api.blocks.getBlock("footer-left-column"),
          // $api.blocks.getBlock("footer-center-column"),
          // $api.blocks.getBlock("footer-right-column"),
          // $api.blocks.getBlock("certificates-entity"),
          // $api.blocks.getBlock("bottom-info"),
          $api.general.getDiscountCampaigns(),
          $api.blocks.getBlock("system-login-footer"),
        ]);

        return {
          dataCopyright: responseCopyright.data,
          dataBootstrap: responseBootstrap.data,
          dataGateways: responseGateways.data,
          dataPaymentMethods: responsePaymentMethods.data,
          dataFooterLeftColumn: responseFooterLeftColumn.data,
          // dataFooterCenterColumn: responseFooterCenterColumn.data,
          // dataFooterRightColumn: responseFooterRightColumn.data,
          // dataCertificatesEntity: responseCertificatesEntity.data,
          // dataBottomInfo: responseBottomInfo.data,
          dataDiscountCampaigns: responseDiscountCampaigns.data,
          dataSystemLoginFooter: responseSystemLoginFooter.data,
        };
      });

      const {
        dataBootstrap,
        dataGateways,
        dataPaymentMethods,
        dataCopyright,
        dataSystemLoginFooter,
        dataFooterLeftColumn,
        // dataFooterRightColumn,
        // dataFooterCenterColumn,
        // dataCertificatesEntity,
        // dataBottomInfo,
        dataDiscountCampaigns,
      } = apiData.value || {};

      this.bootstrap = dataBootstrap
        ? {
            ...dataBootstrap,
            material_categories: sortBy(
              dataBootstrap?.material_categories || [],
              "sort_order",
            ),
          }
        : null;

      // console.log("data - ", dataBootstrap.value);
      // this.bootstrap = dataBootstrap.value?.data
      //   ? dataBootstrap.value?.data
      //   : null;
      this.gatewaysData = dataGateways || null;
      this.paymentMethodsData = dataPaymentMethods || [];

      // this.topPromotion = (
      //   dataTopBanner.value?.data
      //     ?.currentTranslation as IBlockTranslation<BlockContent>
      // )?.content.content;

      this.footer.copyright =
        (dataCopyright?.currentTranslation as IBlockTranslation<BlockContent>)
          ?.content?.content || "";

      this.systemData.loginFooter =
        ((
          dataSystemLoginFooter?.currentTranslation as IBlockTranslation<BlockContent>
        )?.content as RichTextModuleData) || "";

      this.footer.leftColumn =
        (
          dataFooterLeftColumn?.currentTranslation as IBlockTranslation<BlockContent>
        )?.content || "";
      // this.footer.rightColumn =
      //   (
      //     dataFooterRightColumn?.currentTranslation as IBlockTranslation<BlockContent>
      //   )?.content?.content || "";
      //
      // this.footer.centerColumn =
      //   (
      //     dataFooterCenterColumn?.currentTranslation as IBlockTranslation<BlockContent>
      //   )?.content?.content || "";
      //
      // this.footer.certificatesEntities =
      //   ((
      //     dataCertificatesEntity?.currentTranslation as IBlockTranslation<BlockContent>
      //   )?.content as RichTextModuleData) || null;
      //
      // this.footer.bottomInfo =
      //   ((dataBottomInfo?.currentTranslation as IBlockTranslation<BlockContent>)
      //     ?.content as RichTextModuleData) || null;

      this.setDiscountCampaigns(dataDiscountCampaigns || []);
    },

    async getFilter() {
      if (this.filtersData && Object.keys(this.filtersData)?.length) {
        return;
      }
      const { $api } = useNuxtApp();
      const { data: filterData } = await $api.general.getFilters();
      this.filtersData = filterData;
    },
    setSitePopup(payload: IPopup | IDiscountCampaignPopup | null) {
      this.sitePopup = payload;
    },
    setDiscountCampaigns(discountCampaigns: IDiscountCampaign[]) {
      if (!discountCampaigns.length) return;

      const fullDataDiscountCampaigns = discountCampaigns.filter(
        (discountCampaign) => {
          return (
            discountCampaign.is_popup &&
            discountCampaign.currentTranslation?.popup_text &&
            discountCampaign.is_top_bar_text &&
            discountCampaign.currentTranslation?.top_bar_text
          );
        },
      );

      const topBannerDataDiscountCampaigns = discountCampaigns.filter(
        (discountCampaign) =>
          discountCampaign.is_top_bar_text &&
          discountCampaign.currentTranslation?.top_bar_text,
      );

      let topBannerDataDiscountCampaign: IDiscountCampaign | null = null;
      if (fullDataDiscountCampaigns.length) {
        topBannerDataDiscountCampaign =
          getRandomArrayItem(fullDataDiscountCampaigns) || null;
      } else if (topBannerDataDiscountCampaigns.length) {
        topBannerDataDiscountCampaign =
          getRandomArrayItem(topBannerDataDiscountCampaigns) || null;
      }

      const discountCampaignsData: IDiscountCampaignsData = {
        topBanner: null,
        popups: discountCampaigns
          .filter(
            (discountCampaign) =>
              discountCampaign.is_popup &&
              discountCampaign.currentTranslation?.popup_text,
          )
          .map((discountCampaign) => ({
            discount_campaign_id: discountCampaign.id,
            id: `discount_campaign_${discountCampaign.id}`,
            frequency: discountCampaign.frequency,
            currentTranslation: {
              content: discountCampaign.currentTranslation.popup_text,
            },
            pages: discountCampaign.pages,
          })),
      };

      if (topBannerDataDiscountCampaign) {
        discountCampaignsData.topBanner = {
          discount_campaign_id: topBannerDataDiscountCampaign.id,
          top_bar_text:
            topBannerDataDiscountCampaign.currentTranslation.top_bar_text,
        };
      }

      this.discountCampaignsData = discountCampaignsData;
    },
  },
  hydrate(state) {
    // in this case we can completely ignore the initial state since we
    // want to read the value from the browser
    state.recentlyViewedStorage = useStorage("recentlyViewed", []);
  },
});
