import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_cacheControl_QcW09Gkpyh from "/app/plugins/01.cacheControl.ts";
import _03_uid_client_2uBRzE2FiH from "/app/plugins/03.uid.client.ts";
import GTM_client_mP5WQX1gw9 from "/app/plugins/GTM.client.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import appInit_client_5OlePbdpwf from "/app/plugins/appInit.client.ts";
import directives_8CcCirWtnE from "/app/plugins/directives.ts";
import error_ldt3PQauZ0 from "/app/plugins/error.ts";
import firebase_client_zXNDa0wxFH from "/app/plugins/firebase.client.ts";
import general_WpAkvTZxL0 from "/app/plugins/general.ts";
import integrations_client_AgSTKKW7r1 from "/app/plugins/integrations.client.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
import remain_client_Ry2IHq6b0S from "/app/plugins/remain.client.ts";
import scroll_page_T02YBELFpL from "/app/plugins/scroll-page.ts";
import site_popups_client_Wh8U5xF1BY from "/app/plugins/site-popups.client.ts";
import plugin_client_QHzhVBB2Ll from "/app/.nuxt/nuxt-booster/plugin.client.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  plugin_t2GMTTFnMT,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _01_cacheControl_QcW09Gkpyh,
  _03_uid_client_2uBRzE2FiH,
  GTM_client_mP5WQX1gw9,
  api_GFfDXud5Cr,
  appInit_client_5OlePbdpwf,
  directives_8CcCirWtnE,
  error_ldt3PQauZ0,
  firebase_client_zXNDa0wxFH,
  general_WpAkvTZxL0,
  integrations_client_AgSTKKW7r1,
  maska_UHaKf2z1iQ,
  remain_client_Ry2IHq6b0S,
  scroll_page_T02YBELFpL,
  site_popups_client_Wh8U5xF1BY,
  plugin_client_QHzhVBB2Ll
]