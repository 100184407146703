import { useGeneralStore } from "~/stores/general";
import { EVENTS_ID } from "@/configs/_constants";
import {
  AddShippingInfoEvent,
  CartEvent,
  EventGender,
  EventsParams,
  FavoritesEvent,
  PurchaseEvent,
} from "~/types/api/events.types";
import type { Cart, Rate } from "~/types/api/cart.types";
import { WishlistBaggage } from "~/types/api/wishlist.types";
import { IntegrationsType } from "~/types/api/bootstrap.types";

export const intersectionObserverInit = (scrollDepth) => {
  let observer: IntersectionObserver | null = null;

  const initIntersectionObserver = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target as HTMLElement;
        const opEvent = target.getAttribute("data-op-event-id") || "";
        const opWeight = target.getAttribute("data-op-weight") || "";
        const opTag = target.getAttribute("data-op-tag") || "";
        const opScrollDepth = target.getAttribute("data-op-scroll-depth") || "";

        scrollDepth({
          op_event_id: opEvent,
          op_weight: opWeight,
          op_tag: opTag,
          op_scroll_depth: opScrollDepth,
        });

        observer?.unobserve(target);
      }
    });
  };

  const observerOnMounted = () => {
    if (process.client && "IntersectionObserver" in window) {
      observer = new IntersectionObserver(initIntersectionObserver);
      const elements = document.querySelectorAll("[data-op-stats]");
      elements.forEach((element) => {
        observer?.observe(element);
      });
    }
  };

  const observerOnBeforeUnmount = () => {
    if (observer) {
      observer.disconnect();
    }
  };

  return {
    observerOnMounted,
    observerOnBeforeUnmount,
  };
};

export const useEvents = () => {
  const generalStore = useGeneralStore();
  const nuxtApp = useNuxtApp();
  const route = nuxtApp.$router.currentRoute.value;
  const config = useRuntimeConfig();
  const {
    gaAddShippingInfoEvent,
    gaAddToWishlistEvent,
    gaRemoveFromWishlistEvent,
    gaViewWishlistEvent,
    gaViewSearchResultEvent,
    gaViewCartEvent,
    gaGenerateLeadEvent,
  } = useGoogleAnalyticsEvents();
  const {
    fbViewCartEvent,
    fbViewSearchResultEvent,
    fbGenerateLeadEvent,
    fbAddToWishlistEvent,
    fbRemoveFromWishlistEvent,
    fbViewWishlistEvent,
  } = useFacebookPixelEvents();

  const { uidCookie } = useCookiesService();
  const uid = uidCookie.value;

  const userInfo = computed(() => generalStore.userInfo || null);
  const cart_id = computed(() => generalStore.cart?.unique_id);
  const user_id = computed(() => userInfo.value?.id || null);
  const user_email = computed(() => userInfo.value?.email || null);
  const user_phone = computed(() => userInfo.value?.phone || null);
  const sex = computed(
    () =>
      EventGender[(userInfo.value?.gender || "") as keyof typeof EventGender] ??
      null,
  );

  const frontDomain = generalStore.bootstrap?.settings?.front_domain;
  const cleanFrontDomain =
    frontDomain && frontDomain?.trim()?.endsWith("/")
      ? frontDomain.slice(0, -1)
      : frontDomain;
  const baseUrl = cleanFrontDomain || config.public.domain || "";
  const url = `${baseUrl}${route.fullPath}`;
  const language = nuxtApp.$i18n.locale.value;

  const visitEvent = (is_plp: boolean = false) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.VISIT,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
      is_plp: Boolean(is_plp),
    };

    useOperateStatisticsEvent(payload);
    useZendeskAndKlaviyoEvents(payload);
  };

  const addToCartEvent = ({
    product_id,
    configuration_id,
    collection_id,
    product_sku,
    product_price,
  }: CartEvent) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.ADD_TO_CART,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id,
      configuration_id,
      search_query: "",
      scroll_depth: "",
      collection_id: collection_id || "",
      product_sku,
      product_price,
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language,
    };

    useOperateStatisticsEvent(payload);
  };

  const removeFromCartEvent = ({
    product_id,
    configuration_id,
    collection_id,
    product_sku,
    product_price,
  }: CartEvent) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.REMOVE_FROM_CART,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: product_id || "",
      configuration_id: configuration_id || "",
      search_query: "",
      scroll_depth: "",
      collection_id: collection_id || "",
      product_sku: product_sku || "",
      product_price: product_price || "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
  };

  const beginCheckoutEvent = () => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.BEGIN_CHECKOUT,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
  };

  const addPaymentInfoEvent = () => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.ADD_PAYMENT_INFO,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
    useZendeskAndKlaviyoEvents(payload);
  };

  const addShippingInfoEvent = ({
    shipping_email,
    shipping_phone,
  }: AddShippingInfoEvent) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: shipping_email || user_email.value || "",
      user_phone: shipping_phone || user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.ADD_SHIPPING_INFO,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
    useZendeskAndKlaviyoEvents(payload);
  };

  const checkoutErrorEvent = (errorMessage) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.CHECKOUT_ERROR,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: errorMessage || "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
  };

  const purchaseEvent = ({
    purchase_user_email,
    purchase_user_phone,
    order_id,
  }: PurchaseEvent) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: purchase_user_email || "",
      user_phone: purchase_user_phone || "",
      url: url || "",
      event_id: EVENTS_ID.PURCHASE,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id,
      cart_id: "",
      checkout_id: "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
  };

  const searchEvent = (search_query) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.SEARCH,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: search_query || "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
  };

  const addToFavoritesEvent = ({
    product_id,
    configuration_id,
    product_sku,
    product_price,
    collection_id,
    product_title,
  }: FavoritesEvent) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.ADD_TO_FAVORITES,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: product_id || "",
      configuration_id: configuration_id || "",
      search_query: "",
      scroll_depth: "",
      collection_id,
      product_sku: product_sku || "",
      product_price: product_price || "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
    gaAddToWishlistEvent({ product_title, ...payload });
    fbAddToWishlistEvent({ product_title, ...payload });
  };

  const removeFromFavoritesEvent = ({
    product_id,
    configuration_id,
    product_sku,
    product_price,
    product_title,
  }: FavoritesEvent) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: EVENTS_ID.REMOVE_FROM_FAVORITES,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: product_id || "",
      configuration_id: configuration_id || "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: product_sku || "",
      product_price: product_price || "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
    gaRemoveFromWishlistEvent({ product_title, ...payload });
    fbRemoveFromWishlistEvent({ product_title, ...payload });
  };

  const remainEvent = () => {
    const urlRemain = `${baseUrl}${route.fullPath}`;
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: urlRemain || "",
      event_id: EVENTS_ID.REMAIN,
      weight: "",
      sex: sex.value,
      age: null,
      tag: "",
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: "",
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
    useZendeskAndKlaviyoEvents(payload);
  };

  const scrollDepthEvent = ({
    op_event_id,
    op_weight,
    op_tag,
    op_scroll_depth,
  }) => {
    const payload: EventsParams = {
      uid: uid || "",
      user_id: user_id.value || "",
      user_email: user_email.value || "",
      user_phone: user_phone.value || "",
      url: url || "",
      event_id: op_event_id,
      weight: op_weight,
      sex: sex.value,
      age: null,
      tag: op_tag,
      product_id: "",
      configuration_id: "",
      search_query: "",
      scroll_depth: op_scroll_depth,
      collection_id: "",
      product_sku: "",
      product_price: "",
      order_id: "",
      cart_id: cart_id.value || "",
      checkout_id: cart_id.value || "",
      message: "",
      referrer: "",
      language: language || "",
    };

    useOperateStatisticsEvent(payload);
    useZendeskAndKlaviyoEvents(payload);
  };

  const addShippingMethodInfoEvent = ({
    cartData,
    shippingMethodData,
  }: {
    cartData: Cart;
    shippingMethodData: Rate;
  }) => {
    gaAddShippingInfoEvent(cartData, shippingMethodData);
  };

  const viewWishListEvent = (products: WishlistBaggage) => {
    gaViewWishlistEvent(products);
    fbViewWishlistEvent(products);
  };
  const viewSearchResultEvent = (
    data: string,
    type: IntegrationsType = IntegrationsType.TYPE_GOOGLE_ANALYTICS,
  ) => {
    if (type === IntegrationsType.TYPE_GOOGLE_ANALYTICS) {
      gaViewSearchResultEvent(data);
    }
    if (type === IntegrationsType.TYPE_FACEBOOK_PIXEL) {
      fbViewSearchResultEvent(data);
    }
  };

  const viewCartEvent = (
    data?: Cart | null,
    type: IntegrationsType = IntegrationsType.TYPE_GOOGLE_ANALYTICS,
  ) => {
    if (type === IntegrationsType.TYPE_GOOGLE_ANALYTICS) {
      gaViewCartEvent(data);
    }
    if (type === IntegrationsType.TYPE_FACEBOOK_PIXEL) {
      fbViewCartEvent(data);
    }
  };

  const generateLeadEvent = (data: string) => {
    gaGenerateLeadEvent(data);
    fbGenerateLeadEvent(data);
  };

  return {
    visitEvent,
    addToCartEvent,
    removeFromCartEvent,
    beginCheckoutEvent,
    addPaymentInfoEvent,
    addShippingInfoEvent,
    checkoutErrorEvent,
    purchaseEvent,
    searchEvent,
    addToFavoritesEvent,
    removeFromFavoritesEvent,
    remainEvent,
    scrollDepthEvent,
    addShippingMethodInfoEvent,
    viewWishListEvent,
    viewSearchResultEvent,
    viewCartEvent,
    generateLeadEvent,
  };
};
