import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth, signOut } from "firebase/auth";

export default defineNuxtPlugin(() => {
  const generalStore = useGeneralStore();
  const firebaseIntegration = generalStore.getFirebaseIntegration;
  let firebaseApp: FirebaseApp | null = null;
  let auth: Auth | null = null;
  if (firebaseIntegration && !isEmpty(firebaseIntegration.sdk_config)) {
    try {
      firebaseApp = initializeApp(firebaseIntegration.sdk_config);
      auth = getAuth(firebaseApp);
    } catch (error) {
      console.error(error);
    }
  }
  return {
    provide: {
      socialAuth: {
        auth,
        firebaseApp,
        signOut: async () => {
          try {
            if (auth) {
              await signOut(auth);
            }
          } catch (error) {
            console.error(error);
          }
        },
      },
    },
  };
});
