import FetchFactory from "../factory";
import {
  SignInForm,
  SignUpForm,
  SignInResponse,
  SignUpResponse,
  SocialAuthParams,
} from "@/types/api/auth.types";

class AuthModule extends FetchFactory {
  /**
   * Lоgin
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1Authorizations
   * @param data
   * @returns Promise
   */
  signIn(data: SignInForm) {
    return this.fetch<SignInResponse>("POST", "/authorizations", {
      body: data,
    });
  }

  /**
   * Register
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1AuthorizationsSignUp
   * @param data
   * @returns Promise
   */
  signUp(data: SignUpForm) {
    return this.fetch<SignUpResponse>("POST", "/authorizations/sign-up", {
      body: data,
    });
  }

  /**
   * Logout
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-DeleteApiV1Authorizations
   * @returns Promise
   */
  signOut() {
    return this.fetch("DELETE", "/authorizations", {});
  }

  /**
   * Social auth
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1AuthorizationsSocial
   * @param {Object} data
   * @param {string} data.token
   * @param {string} [data.cart_unique_id]
   * @returns Promise
   */
  socialAuth(data: SocialAuthParams) {
    return this.fetch<SignInResponse>("post", "/authorizations/social", {
      body: data,
    });
  }
}

export default AuthModule;
