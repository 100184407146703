import { storeToRefs } from "pinia";
import { useGeneralStore } from "~/stores/general";
import { OrderData } from "@/types/api/orders.types";
import { Cart, CartItem } from "~/types/api/cart.types";

export const useFacebookPixelEvents = () => {
  const { $i18n } = useNuxtApp();
  const generalStore = useGeneralStore();
  const {
    getIntegrationCallbackStatuses: integrationCallbackStatuses,
    settings,
  }: any = storeToRefs(generalStore);
  const isFacebookPixelAvailable = computed(() => {
    return (
      integrationCallbackStatuses.value.TYPE_FACEBOOK_PIXEL &&
      Boolean((window as any)?.fbq)
    );
  });
  function fbPriceFormat(price: any) {
    price = price || 0;
    return Number(price).toFixed(2);
  }
  function fbViewContentEvent(data: any, configurationData: any) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "ViewContent", {
      content_type: "product",
      content_ids: [configurationData?.configuration_id],
      content_name: data?.currentTranslation?.title || data?.title || "",
      content_category: data.collections?.[0]?.currentTranslation?.title || "",
      value: fbPriceFormat(configurationData?.cost || data.base_cost),
      currency: settings.value?.currency_code || "",
    });
  }
  function fbAddToCartEvent(data: any, configurationData: any) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "AddToCart", {
      content_type: "product",
      content_ids: [configurationData?.configuration_id],
      content_name: data?.currentTranslation?.title || data?.title || "",
      content_category:
        data.collections?.[0]?.currentTranslation?.title ||
        data.collections_ids?.[0] ||
        "",
      value: fbPriceFormat(configurationData?.cost || data.base_cost),
      currency: settings.value?.currency_code || "",
    });
  }
  function fbInitiateCheckoutEvent(data: Cart) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "InitiateCheckout", {
      content_type: "product",
      content_name: $i18n.t("checkout"),
      value: fbPriceFormat(data.final_amount),
      currency: settings.value?.currency_code || "",
      num_items: data.items.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      ),
      content_ids: data.items?.map((item: any) => item.configuration_id),
    });
  }
  function fbPurchaseEvent(data: OrderData) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "Purchase", {
      content_type: "product",
      content_name: $i18n.t("orderPlaced"),
      value: fbPriceFormat(data.final_amount),
      currency: data.currency_code,
      num_items: data.order_products.reduce(
        (acc: number, item: any) => acc + item.quantity,
        0
      ),
      content_ids: data.order_products?.map(
        (item: any) => item.configuration_id
      ),
    });
  }
  function fbViewCartEvent(data?: Cart | null) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "view_cart", {
      value: fbPriceFormat(data?.final_amount), // сума транзакції
      currency: settings.value?.currency_code || "", // валюта, якщо гривні, то UAH, якщо доллар, то USD
      items: data?.items?.map((item) => ({
        item_name: item?.currentTranslation?.title, // назва товару
        item_id: item.id, // ідентифікатор товару
        price: fbPriceFormat(item.base_cost), // ціна товару
        item_brand: generalStore.settings?.company_name, // бренд товару
        item_category: "", // категорія товару
        item_variant: item.configuration_id, // варіант товару
        quantity: item.quantity, // кількість замовленого товару
      })),
    });
  }
  function fbViewSearchResultEvent(data: string) {
    if (!isFacebookPixelAvailable.value || !data) {
      return;
    }
    (window as any).fbq("track", "view_search_results", {
      search_term: data,
    });
  }
  function fbGenerateLeadEvent(lead_source: string) {
    if (!isFacebookPixelAvailable.value || !lead_source) {
      return;
    }
    (window as any).fbq("track", "generate_lead", {
      currency: settings.value?.currency_code || "",
      value: 0,
      lead_source,
    });
  }
  function fbBeginCheckoutEvent(data: CartItem[]) {
    if (!isFacebookPixelAvailable.value || !data?.length) {
      return;
    }
    (window as any).fbq("track", "begin_checkout", {
      items: data.map((item, index) => ({
        item_name: item?.currentTranslation?.title || item?.title, // назва товару
        item_id: item.id, // ідентифікатор товару
        price: fbPriceFormat(item.base_cost), // ціна товару
        item_brand: generalStore.settings?.company_name, // бренд товару
        item_category: "", // категорія товару
        item_category2: "", // другий рівень категорії товару
        item_category3: "", // третій рівень категорії товару
        item_category4: "", // четвертий рівень категорії товару
        item_variant: item.configuration_id, // варіант товару
        item_list_name: "", // назва списку товарів
        item_list_id: item.configuration_id, // унікальний ідентифікатор переліку товарі
        index: index + 1, // позиція товару у переліку товарів
        quantity: item.quantity, // кількість замовленого товару
      })),
    });
  }
  function fbAddToWishlistEvent(product: any) {
    if (!isFacebookPixelAvailable.value || !product) {
      return;
    }
    (window as any).fbq("track", "add_to_wishlist", {
      currency: settings.value?.currency_code || "",
      value: product.product_price,
      items: [
        {
          item_name: product?.product_title, // назва товару
          item_id: product?.product_id, // ідентифікатор товару
          price: fbPriceFormat(product.product_price), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: "", // категорія товару
          item_variant: product.configuration_id, // варіант товару
        },
      ],
    });
  }
  function fbRemoveFromWishlistEvent(product: any) {
    if (!isFacebookPixelAvailable.value || !product) {
      return;
    }
    (window as any).fbq("track", "favorites_remove", {
      currency: settings.value?.currency_code || "",
      value: product.product_price,
      items: [
        {
          item_name: product?.product_title, // назва товару
          item_id: product?.product_id, // ідентифікатор товару
          price: fbPriceFormat(product.product_price), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: "", // категорія товару
          item_variant: product.configuration_id, // варіант товару
        },
      ],
    });
  }
  function fbViewWishlistEvent(products: any) {
    if (!isFacebookPixelAvailable.value || !products) {
      return;
    }
    (window as any).fbq("track", "favorites_view", {
      currency: settings.value?.currency_code || "",
      value: products.reduce(
        (acc, product: any) => acc + Number(product.cost),
        0,
      ),
      items: products.map((product: any) => ({
        item_name: product?.currentTranslation?.title, // назва товару
        item_id: product?.currentTranslation?.id, // ідентифікатор товару
        price: fbPriceFormat(product.cost), // ціна товару
        item_brand: generalStore.settings?.company_name, // бренд товару
        item_category: "", // категорія товару
        item_variant: product.configuration_id, // варіант товару
      })),
    });
  }
  return {
    integrationCallbackStatuses,
    fbViewContentEvent,
    fbAddToCartEvent,
    fbInitiateCheckoutEvent,
    fbPurchaseEvent,
    fbViewCartEvent,
    fbViewSearchResultEvent,
    fbGenerateLeadEvent,
    fbBeginCheckoutEvent,
    fbAddToWishlistEvent,
    fbRemoveFromWishlistEvent,
    fbViewWishlistEvent,
  };
};
